import React from 'react'

const UserIcon = (props:any) => {
  return (
    <svg {...props} width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.643 7.57142C9.4182 7.57142 10.8573 6.13234 10.8573 4.35714C10.8573 2.58194 9.4182 1.14285 7.643 1.14285C5.8678 1.14285 4.42871 2.58194 4.42871 4.35714C4.42871 6.13234 5.8678 7.57142 7.643 7.57142Z" stroke="#333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0715 18.2857H1.21436V16.8571C1.21436 15.1522 1.89165 13.517 3.09724 12.3114C4.30283 11.1059 5.93796 10.4286 7.64293 10.4286C9.34789 10.4286 10.983 11.1059 12.1886 12.3114C13.3942 13.517 14.0715 15.1522 14.0715 16.8571V18.2857Z" stroke="#333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.3574 1.14285C14.2099 1.14285 15.0275 1.4815 15.6303 2.0843C16.2331 2.68709 16.5717 3.50466 16.5717 4.35714C16.5717 5.20962 16.2331 6.02719 15.6303 6.62998C15.0275 7.23278 14.2099 7.57142 13.3574 7.57142" stroke="#333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.6431 10.7C16.86 11.1629 17.9076 11.9844 18.6474 13.0558C19.3872 14.1272 19.7842 15.398 19.7859 16.7V18.2857H17.6431" stroke="#333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default UserIcon