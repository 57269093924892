import React from "react";

const PackagesIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.57136 0.714294H1.99993C1.60544 0.714294 1.28564 1.03409 1.28564 1.42858V18.5714C1.28564 18.9659 1.60544 19.2857 1.99993 19.2857H5.57136C5.96585 19.2857 6.28564 18.9659 6.28564 18.5714V1.42858C6.28564 1.03409 5.96585 0.714294 5.57136 0.714294Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5714 3.57144H6.99993C6.60544 3.57144 6.28564 3.89124 6.28564 4.28573V18.5714C6.28564 18.9659 6.60544 19.2857 6.99993 19.2857H10.5714C10.9658 19.2857 11.2856 18.9659 11.2856 18.5714V4.28573C11.2856 3.89124 10.9658 3.57144 10.5714 3.57144Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3523 3.18033L12.5806 3.87396C12.1979 3.96973 11.9653 4.35759 12.0611 4.74028L15.5292 18.5986C15.625 18.9813 16.0129 19.2139 16.3955 19.1181L19.1672 18.4245C19.5499 18.3287 19.7825 17.9409 19.6867 17.5582L16.2186 3.69984C16.1228 3.31716 15.735 3.08456 15.3523 3.18033Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.28564 14.2857H6.28564"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.28564 12.8571H11.2856"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8569 15.7143L18.9712 14.6857"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PackagesIcon;
