import styled from "styled-components";

export const UploadContent = styled.div<any>`
  position: relative;
  width: 124px;
  height: 124px;
  background-color: ${({ theme }) => theme.colors.bg.whiteBg};
  border: 1px dashed
    ${(props) => props.theme.colors.border[props.error ? "red" : "lightBlack"]};
  overflow: hidden;
  &:hover {
    cursor: pointer;
    border-color: ${(props) =>
      props.theme.colors.border[props.error ? "red" : "blue"]};
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    input {
      display: none;
    }
    .uploadIcon {
      width: 24px;
      height: 24px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .uploadImage {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.bg.whiteBg};
    }
    .loaderContent {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
  label.loading {
    opacity: 0.4;
  }
`;
