import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { REQUEST_STATUS, useRequest } from "hooks";
import { CancelButton, Button, Icons } from "components";
import { DeleteButtonPropsType } from "./DeleteButton.types";

import { ERROR_MESSAGES } from "constants/error";

const DeleteButton: FC<DeleteButtonPropsType> = ({
  backPath = "",
  deleteUrl = "",
  setRender = () => {},
  returnResponseFunction = () => {},
  queryKey = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [client, data, status, error] = useRequest();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      if (backPath) {
        navigate(backPath);
      }
      returnResponseFunction(data);
      setOpen(false);
      setRender((prev) => !prev);
      toast.success(t(ERROR_MESSAGES[data?.statusCode]));
    }
  }, [status]);

  const deleteElement = async () => {
    if (deleteUrl) {
      await client.deleteRequest(deleteUrl);
      if (!!queryKey) {
        await queryClient.invalidateQueries([queryKey]);
      }
    }
  };

  return (
    <div onClick={(e: any) => e.stopPropagation()}>
      <div onClick={() => setOpen(true)} className="hover">
        <Icons.Delete />
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="px-3 pt-3 mb-2">Rostdan ham o'chirmoqchimisz?</div>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <CancelButton
                value="Yoq"
                type="button"
                onClick={() => setOpen(false)}
              />
            </Grid>
            <Grid item>
              <Button value="Ha" type="button" onClick={deleteElement} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteButton;
