import styled from "styled-components";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";

export const StyledTable = styled(Table)`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.bg.whiteBg};
  border-collapse: inherit !important;
`;

export const TableCellStyled = styled(TableCell)`
  color: ${({ theme }) => theme.colors.textColor};
`;

export const TableBodyStyled = styled(TableBody)<any>`
  row-gap: 0;
  border: none !important;
  overflow-y: scroll;
  .MuiTableRow-root.dragStartHandlerRow {
    /* border: 1px solid red !important; */
    background: red !important;
  }
  .columns {
    transition: all 0.3s;
    border-collapse: inherit !important;
    border-width: 0 !important;
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:last-child {
      text-align: end;
    }
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
      color: ${({ theme }) => theme.colors.text.lightBlack} !important ;
    }
  }
`;

export const TableCellCheckbox = styled(TableCell)`
  width: 50px !important;
`;

export const TableContainerStyled = styled(TableContainer)<any>`
  max-height: calc(100vh - 132px - ${({ height }: any) => height + "px"});
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
