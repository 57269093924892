import { Button } from "@mui/material";
import styled from "styled-components";

export const CancelButtonStyled = styled(Button)`
  max-width: 224px;
  min-width: 200px;
  height: 48px;
  padding: 12px 32px !important;
  border-radius: 12px !important;
  color: ${({ theme }) => theme.colors.button.cancel} !important;
  border-color: ${({ theme }) => theme.colors.button.cancelBorder} !important;
  background-color: transparent !important;
  text-transform: capitalize !important ;
`;
