import styled from "styled-components";
import { height } from "./Navber.constants";

export const StyledNavbar = styled.div<any>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: all 0.3s linear;
  min-height: ${height};
  height: ${height};
  z-index: 100;
  max-width: 2000px;
  margin: 0 auto;

  & .MuiAppBar-root {
    height: 100%;
    position: relative;
    background-color: ${({ theme }) => theme.colors.bg.whiteBg};
    box-shadow: none;
    border-radius: 12px;
    width: 100%;
    box-shadow: 3px 5px 5px 1px #80747445;

    .MuiContainer-root.MuiContainer-maxWidthXl {
      margin: 0 !important;
      min-height: 100%;
      .MuiToolbar-root.MuiToolbar-regular {
        min-height: 100%;
      }
    }
  }
  .navbarContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    .navbarProfile {
      margin-left: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      .navbarProfileRight {
        margin-left: 7px;
      }
      .navbarProfileLeft {
        .name,
        .text {
          color: ${({ theme }) => theme.colors.text.black};
        }
        .name {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 1px;
          text-align: end;
        }
        .text {
          font-size: 14px;
          text-align: end;
        }
      }
    }
  }
`;
