import React from 'react'

const PublishingIcon = (props:any) => {
  return (
    <svg {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9286 19.2857H2.64293C2.26405 19.2857 1.90068 19.1352 1.63277 18.8673C1.36487 18.5994 1.21436 18.236 1.21436 17.8572V2.14287C1.21436 1.76399 1.36487 1.40062 1.63277 1.13271C1.90068 0.864804 2.26405 0.714294 2.64293 0.714294H13.3572" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.5 5.00001L17.6429 0.714294L19.7857 5.00001V17.1429C19.7857 17.7112 19.56 18.2562 19.1581 18.6581C18.7562 19.06 18.2112 19.2857 17.6429 19.2857C17.0745 19.2857 16.5295 19.06 16.1276 18.6581C15.7258 18.2562 15.5 17.7112 15.5 17.1429V5.00001Z" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.5 13.5714H19.7857" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.5 0.714294V19.2857" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.07129 5.71429H11.9284" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default PublishingIcon