import styled from "styled-components";

export const DatePickerStyled = styled.div`
  .MuiFormControl-root.MuiTextField-root {
    display: block;
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl {
      width: 100%;
      .MuiOutlinedInput-input.MuiInputBase-input {
        padding: 14.5px;
      }
      .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
      .css-1v4ccyo.Mui-error.MuiOutlinedInput-notchedOutline {
        border-radius: 8px;
      }
    }
  }
  fieldset {
    border: 1px solid ${({ theme }) => theme.colors.border.grey} !important;
  }

  &.error {
    fieldset {
      border-color: ${({ theme }) => theme.colors.border.red} !important;
    }
  }
  .not-valid{
    font-size: 13px;
    margin: 5px 0;
    color: red;
  }
`;
