import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { get } from "lodash";
import { TParams } from "modules/_module";
import qs from "qs";

import browserStorage from "services/storage/browserStorage";

const language = browserStorage.get<string | undefined>("i18nextLng");

const config: AxiosRequestConfig = {
  timeout: 100000,
};

const createExtraParams = (config: AxiosRequestConfig): string => {
  const url: string = get(config, "url", "");
  let params: Partial<TParams> = get(config, "params", {});

  if (params.hasOwnProperty("extra")) {
    const extra: Object = get(params, "extra", {});
    params = {
      ...params,
      ...(extra || {}),
      extra: undefined,
    };
  }
  return Object.keys(params).length ? `${url}?${qs.stringify(params)}` : url;
};

axios.interceptors.request.use(
  (config: any) => {
    const token = browserStorage.get("token");

    return {
      ...config,
      baseURL: "https://backend.book.uz/admin-api/",
      // window.location.origin !== "https://admin-book.vercel.app"
      //   ? process.env.REACT_APP_BASE_URL
      //   : "https://dev.book.uz/admin-api/",
      timeout: 100000,
      url: createExtraParams(config),
      params: {},
      headers: {
        ...config.headers,
        language: language ? (language === "de" ? "latin" : language) : "uz",
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosInstance: AxiosInstance = axios.create(config);

export default axios;
