import styled from "styled-components";
import { Button } from "@mui/material";

export const SaveButtonStyled = styled(Button)`
  max-width: 224px;
  min-width: 200px;
  height: 40px;
  position: relative;

  padding: 8px 16px !important;
  border-radius: 12px !important;
  text-transform: none !important ;
  background-color: ${({ theme }) => theme.colors.button.blue} !important;

  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 24px !important;

  &:disabled {
    color: #fff !important;

    background-color: gray !important;
    cursor: not-allowed !important;
  }
`;
