import { REQUEST_STATUS, useRequest } from "hooks";
import get from "lodash.get";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import browserStorage from "services/storage/browserStorage";
import { ILoginForm } from "../components/LoginForm/LoginForm.types";
import { IUseLogin } from "./LoginContext.types";

export const useLogin = (): IUseLogin => {
  const navigate = useNavigate();
  const [loginClient, loginData, loginStatus, loginError] = useRequest();
  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest();

  const loginFunction = async (loginRequest: ILoginForm) => {
    await loginClient.post("/sign-in", loginRequest);
  };

  const getRole = async (id: string) => {
    await getRoleClient.get(`employee/profile`);
  };

  const updateWindow = async () => {
    if (loginStatus === REQUEST_STATUS.success) {
      browserStorage.set("token", get(loginData, "data.token"));
      browserStorage.set("admin", get(loginData, "data.admin"));
      getRole(get(loginData, "data.admin")?._id);
    }
  };
  const role = async () => {
    if (getRoleStatus === REQUEST_STATUS.success) {
      browserStorage.set("role", get(getRoleData, "data.role"));
      await navigate("/");
    }
  };

  useEffect(() => {
    updateWindow();
  }, [loginStatus]);

  useEffect(() => {
    role();
  }, [getRoleStatus]);

  return {
    state: {
      loginState: { loginData, loginStatus, loginError },
      getRoleState: { getRoleData, getRoleStatus, getRoleError },
    },
    actions: { loginFunction },
  };
};
