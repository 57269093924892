import { FC } from "react";
import Spinner from "components/common/Spinner";

import { SaveButtonStyled } from "./Button.style";
import { SaveButtonType } from "./Button.types";

const Button: FC<SaveButtonType> = (props) => {
  const {
    onClick,
    type,
    value,
    disabled,
    className,
    symbol = false,
    isLoading,
    myForm = "",
  } = props;

  return (
    <SaveButtonStyled
      className={className}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {symbol && <> &#43; </>}
      {isLoading ? <Spinner position="absolute" md={true} /> : ""}
      {value}
    </SaveButtonStyled>
  );
};

export default Button;
