import React from "react";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import browserStorage from "services/storage/browserStorage";
import { LanguageList } from "./LanguageList.constants";
import { LanguageSelectStyled } from "./LanguageSelect.style";
import { TLanguage } from "./LanguageSelect.types";
import { defaultLanguage } from "constants/language";

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const defaultLanguageStorage = browserStorage.get("i18nextLng");
  const changeLanguage = (language: TLanguage) => {
    i18n.changeLanguage(language);
  };

  return (
    <LanguageSelectStyled
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      defaultValue={defaultLanguageStorage || defaultLanguage}
      label=""
      onChange={(e: any) => changeLanguage(e.target.value)}
    >
      {LanguageList.map((language, index: number) => {
        return (
          <MenuItem value={language.value} key={index}>
            {language.label}
          </MenuItem>
        );
      })}
    </LanguageSelectStyled>
  );
};

export default LanguageSelect;
