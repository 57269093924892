import React from 'react'

const OrderIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2146 4.28574H9.78599C8.99702 4.28574 8.35742 4.92533 8.35742 5.71431V12.1429C8.35742 12.9319 8.99702 13.5715 9.78599 13.5715H16.2146C17.0035 13.5715 17.6431 12.9319 17.6431 12.1429V5.71431C17.6431 4.92533 17.0035 4.28574 16.2146 4.28574Z" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.21436 17.1429H17.0858C17.4606 17.1444 17.8197 17.2932 18.0858 17.5571L19.7858 19.2857" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.21436 0.714294H2.64293C3.02181 0.714294 3.38517 0.864804 3.65308 1.13271C3.92099 1.40062 4.0715 1.76399 4.0715 2.14287V14.2857" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.21452 16.7857C6.22158 16.9047 6.22158 17.0239 6.21452 17.1429C6.16827 17.785 5.8747 18.3841 5.3956 18.8141C4.9165 19.2442 4.28925 19.4715 3.64588 19.4484C3.00252 19.4253 2.39322 19.1534 1.94625 18.6901C1.49928 18.2267 1.24951 17.6081 1.24951 16.9643C1.24951 16.3205 1.49928 15.7018 1.94625 15.2385C2.39322 14.7752 3.00252 14.5033 3.64588 14.4802C4.28925 14.457 4.9165 14.6844 5.3956 15.1144C5.8747 15.5445 6.16827 16.1436 6.21452 16.7857V16.7857Z" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.6431 10H14.0716" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default OrderIcon