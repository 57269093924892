import React from 'react'

const bookIcon = (props:any) => {
  return (
    <svg {...props} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3574 19.3429H2.78592C2.2176 19.3429 1.67256 19.1171 1.27069 18.7152C0.868831 18.3134 0.643066 17.7683 0.643066 17.2C0.643066 16.6317 0.868831 16.0866 1.27069 15.6848C1.67256 15.2829 2.2176 15.0571 2.78592 15.0571H14.9288C15.3077 15.0571 15.671 14.9066 15.9389 14.6387C16.2068 14.3708 16.3574 14.0074 16.3574 13.6286V2.19999C16.3574 1.82111 16.2068 1.45775 15.9389 1.18984C15.671 0.921933 15.3077 0.771423 14.9288 0.771423H2.78592C2.22737 0.771225 1.69081 0.989129 1.29055 1.37872C0.890285 1.7683 0.657961 2.29878 0.643066 2.85714V17.1429" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default bookIcon