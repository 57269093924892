export const schema: any = {
  options: {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Zoom qilishni yoqish
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    grid: {
      padding: {
        right: 40,
        left: 40,
      },
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0,
      },
    },
    markers: {
      size: 0,
      hover: {
        size: 8,
        fillColor: "#fff",
        strokeColor: "#4072EE",
        radius: 2,
      },
    },

    tooltip: {
      enabled: true,
      intersect: false,
      x: {
        show: false,
      },
      custom: function ({ series, _, dataPointIndex, w }: any) {
        const xValue = series[0][dataPointIndex] || 0;
        return '<div class="custom-tooltip">' + xValue + "</div>";
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Iyun",
        "Iyul",
        "Avg",
        "Sen",
        "Okt",
        "Nov",
        "Dek",
      ],
      show: false,
      tooltip: {
        enabled: false,
      },
      tickPlacement: "between",
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#748AA1",
          font: "400 14px/16px Inter",
        },
      },
    },
    yaxis: {
      show: false,
    },
  },
  series: [
    {
      name: "",
      data: [28, 29, 33, 36, 32, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};
