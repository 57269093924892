import React from "react";
import ReactApexChart from "react-apexcharts";

interface IDonut {
  data?: Array<any>;
  colors?: Array<any>;
}

const Pie = ({ data = [44, 24], colors = ["#6C2CBD", "#C2C3C2"] }: IDonut) => {
  var options: any = {
    chart: {
      width: 80,
      type: "donut",
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "linear",
    },
    title: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };
  return (
    <ReactApexChart type="donut" width={100} options={options} series={data} />
  );
};

export default Pie;
