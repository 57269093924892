import styled from "styled-components";

export const UploadImageCardStyled = styled("div")`
  /* display: inline-block; */
  border: 1px solid #ccc;
  width: 110px;
  height: 110px;
  min-width: 110px;
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .upload-result-image-content {
    &:hover {
      .upload-result-image-actions {
        visibility: visible;
      }
    }
    .upload-result-image-actions {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 15;
      background: rgba(0, 0, 0, 0.7);
      visibility: hidden;
      div {
        position: relative;
        z-index: 25;
        span {
          height: fit-content;
          border: none;
          outline: none;
          padding: 0;
          background: transparent;
        }
        span:first-child {
          margin-right: 7px;
        }
      }
    }
    .upload-result-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
`;
