import React, { FC } from "react";
import MultipleUploadImageProvider from "../context/MultipleUploadImageProvider";
import MultipleUploadImage from "./MultipleUploadImage";
import { IMultipleUploadImage } from "./MultipleUploadImage.types";

const Index: FC<IMultipleUploadImage> = (props) => {
  return (
    <MultipleUploadImageProvider>
      <MultipleUploadImage {...props} />
    </MultipleUploadImageProvider>
  );
};

export default Index;
