import styled from "styled-components";

export const MultipleUploadImageStyled = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
