import React, { FC } from "react";
import { TablePagination, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TablePaginationActions } from "../index";
import { TableFooterStyled } from "./TableFooter.style";
import { ITableFooterProps, TRowsPerPageOptions } from "./TableFooter.types";

const TableFooter: FC<ITableFooterProps> = ({
  colSpan,
  count,
  handleChangePage,
  page,
  handleChangeRowsPerPage,
  rowsPerPage,
  draggable = false,
}) => {
  const { t } = useTranslation();
  let rowsPerPageOptions: TRowsPerPageOptions[] = [5, 10, 25, 50];
  if (draggable) rowsPerPageOptions.push({ label: "All", value: -1 });
  return (
    <TableFooterStyled>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: false,
          }}
          labelRowsPerPage={t("ROWS_PER_PAGE")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          //@ts-ignore
          ActionsComponent={() => (
            <TablePaginationActions
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          )}
        />
      </TableRow>
    </TableFooterStyled>
  );
};

export default TableFooter;
