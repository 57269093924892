export const theme = {
  colors: {
    bg: {
      mainBg: "#F8F8F8",
      whiteBg: "#ffffff",
      blue: "#00B2F5",
      darkBlue: "#1da1d1",
      mainLightBg: "#F6F6F9",
    },
    text: {
      lightBlack: "#313949",
      black: "#000000",
      red: "red",
      blue: "#00B2F5",
      white: "#ffffff",
    },
    color: {
      blue: "#00B2F5",
      black: "#000000",
      lightBlack: "#333333",
      red: "red",
      lightGrey: "#EDF1FF",
    },
    border: {
      blue: "#00B2F5",
      grey: "rgba(49, 57, 73, 0.1)",
      red: "red",
      lightBlack: "#ccc",
      warning: "rgb(255, 152, 0)",
    },
    button: {
      blue: "#00B2F5",
      red: "red",
      cancel: "#1A1D1F",
      cancelBorder: "#EFEFEF",
    },
  },
};
