import React from "react";

const CategoryIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1199_5688)">
        <path
          d="M6.92864 0.714233H2.64293C1.85395 0.714233 1.21436 1.35383 1.21436 2.1428V6.42852C1.21436 7.2175 1.85395 7.85709 2.64293 7.85709H6.92864C7.71762 7.85709 8.35721 7.2175 8.35721 6.42852V2.1428C8.35721 1.35383 7.71762 0.714233 6.92864 0.714233Z"
          stroke="#000001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3574 0.714233H14.0716C13.2827 0.714233 12.6431 1.35383 12.6431 2.1428V6.42852C12.6431 7.2175 13.2827 7.85709 14.0716 7.85709H18.3574C19.1463 7.85709 19.7859 7.2175 19.7859 6.42852V2.1428C19.7859 1.35383 19.1463 0.714233 18.3574 0.714233Z"
          stroke="#000001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.92864 12.1428H2.64293C1.85395 12.1428 1.21436 12.7824 1.21436 13.5714V17.8571C1.21436 18.6461 1.85395 19.2857 2.64293 19.2857H6.92864C7.71762 19.2857 8.35721 18.6461 8.35721 17.8571V13.5714C8.35721 12.7824 7.71762 12.1428 6.92864 12.1428Z"
          stroke="#000001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3574 12.1428H14.0716C13.2827 12.1428 12.6431 12.7824 12.6431 13.5714V17.8571C12.6431 18.6461 13.2827 19.2857 14.0716 19.2857H18.3574C19.1463 19.2857 19.7859 18.6461 19.7859 17.8571V13.5714C19.7859 12.7824 19.1463 12.1428 18.3574 12.1428Z"
          stroke="#000001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1199_5688">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CategoryIcon;
