import React from "react";
import { LoaderContent } from "./Loader.style";

const Loader = (props: any) => {
  const { fullWidth = false } = props;
  return (
    <LoaderContent fullWidth={fullWidth}>
      <div className="loader-page">
        <div className="loader">Loading...</div>
      </div>
    </LoaderContent>
  );
};

export default Loader;
