import React, { FC } from "react";
import { TextAreaPropsType } from "./TextArea.types";
import { Label, Error } from "../../index";
import { TextAreaStyled } from "./TextArea.style";
const TextArea: FC<TextAreaPropsType> = ({
  label,
  className,
  params,
  error,
}) => {
  return (
    <div className={className}>
      <Label label={label} />
      <TextAreaStyled {...params} error={!!error} />
      {error && <Error message={error.message} />}
    </div>
  );
};

export default TextArea;
