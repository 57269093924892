import { Dialog, DialogContent } from "@mui/material";
import React, { FC, useState } from "react";
import UploadViewIcon from "../../assets/UploadViewIcon";
import { UploadImageViewStyled, ViewImage } from "./UploadImageView.style";
import { IUploadImageView } from "./UploadImageView.types";

const UploadImageView: FC<IUploadImageView> = ({ imgUrl }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <UploadImageViewStyled>
      <span onClick={handleOpenDialog}>
        <UploadViewIcon />
      </span>

      <Dialog fullWidth open={open} onClose={handleCloseDialog} maxWidth="sm">
        <DialogContent>
          <ViewImage src={imgUrl} className="view-upload-image" alt="" />
        </DialogContent>
      </Dialog>
    </UploadImageViewStyled>
  );
};

export default UploadImageView;
