import styled from "styled-components";
import MaskInput from "react-input-mask";
export const MaskInputStyled = styled(MaskInput)`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.grey};
  box-sizing: border-box;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.lightBlack};
  padding: 16px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  outline: none;
  min-width: 350px;
  transition: all 300ms ease-out;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.grey};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.border.grey};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.lightBlack};
  }
  &:focus {
    box-shadow: none;
    color: #8b90a5;
    border-color: #edf1ff;
  }
  &.error {
    border-color: ${({ theme }) => theme.colors.border.red};
  }
  @media screen and (max-width: 1200px) {
    min-width: 300px;
  }
  @media screen and (max-width: 1100px) {
    min-width: 250px;
  }
  @media screen and (max-width: 1000px) {
    min-width: 200px;
  }
`;
