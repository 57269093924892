import styled from "styled-components";

export const LabelStyled = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #6f767e;
  margin-bottom: 8px !important;
  display: block;
`;
