import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input, PhoneInput, Title } from "components";
import { LoginButton } from "../index";
import { formNames } from "./LoginForm.constants";
import { phoneNumberPattern } from "constants/pattern";
import { ILoginForm } from "./LoginForm.types";
import { LoginContext } from "pages/login/context";
import { REQUEST_STATUS } from "hooks";

const LoginForm = () => {
  const { t } = useTranslation();

  const {
    state: {
      loginState: { loginStatus },
    },
    actions: { loginFunction },
  } = useContext(LoginContext);

  const { register, handleSubmit, formState, control } = useForm<ILoginForm>();

  const loginFormFunction = (formData: any) => {
    let loginRequest = {
      ...formData,
      [formNames.phoneNumber]: formData[formNames.phoneNumber]?.replace(
        /\s+/g,
        ""
      ),
    };
    loginFunction(loginRequest);
  };

  return (
    <form onSubmit={handleSubmit(loginFormFunction)}>
      <Title title={t("LOGIN.ADMIN_PANEL")} className="mb-3" />
      <PhoneInput
        control={control}
        name={formNames.phoneNumber}
        mask="+\9\98 99 999 99 99"
        maskChar=""
        rules={{
          required: {
            value: true,
            message: t("VALIDATION.ENTER_PHONE"),
          },
          minLength: {
            value: 9,
            message: t("VALIDATION.ENTER_PHONE_FULL"),
          },
          pattern: {
            value: phoneNumberPattern,
            message: t("VALIDATION.ENTER_NUMBER"),
          },
        }}
        error={formState.errors[formNames.phoneNumber]}
        label={t("COMMON.LOGIN")}
        className="mb-4"
      />

      <Input
        params={{
          ...register(formNames.password, {
            required: { value: true, message: t("VALIDATION.ENTER_PASSWORD") },
            minLength: { value: 6, message: t("VALIDATION.PASSWORD") },
          }),
          type: "password",
        }}
        error={formState.errors[formNames.password]}
        label={t("LOGIN.PASSWORD")}
        className="mb-5"
      />
      <LoginButton
        type="submit"
        value={t("COMMON.LOGIN")}
        disabled={loginStatus === REQUEST_STATUS.loading}
      />
    </form>
  );
};

export default LoginForm;
