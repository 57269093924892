import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { routes } from "routes/constants/Routes.constants";
import RoleManager from "services/roleManagement";

const Routes = () => {
  const [hasAccess] = RoleManager();

  return (
    <Switch>
      {routes.map((route, index) => (
        <React.Fragment key={index}>
          {hasAccess(route.key) && <Route {...route} />}
        </React.Fragment>
      ))}
    </Switch>
  );
};

export default Routes;
