import React from "react";

const UploadAudioIcon = (props: any) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1785 0.642822H1.82136C1.11128 0.642822 0.535645 1.21846 0.535645 1.92854V16.0714C0.535645 16.7815 1.11128 17.3571 1.82136 17.3571H10.1785C10.8886 17.3571 11.4642 16.7815 11.4642 16.0714V1.92854C11.4642 1.21846 10.8886 0.642822 10.1785 0.642822Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.77559 9.17993V4.91136C3.77217 4.83919 3.78842 4.76746 3.82262 4.70382C3.85681 4.64018 3.90766 4.58703 3.96973 4.55005C4.03179 4.51308 4.10274 4.49367 4.17498 4.4939C4.24723 4.49413 4.31805 4.51399 4.37988 4.55136L8.04417 6.6985C8.10569 6.73321 8.15688 6.78364 8.19251 6.84464C8.22813 6.90564 8.2469 6.97501 8.2469 7.04564C8.2469 7.11628 8.22813 7.18565 8.19251 7.24665C8.15688 7.30764 8.10569 7.35808 8.04417 7.39279L4.37988 9.52707C4.31899 9.56377 4.2494 9.58352 4.17831 9.58427C4.10722 9.58501 4.03723 9.56673 3.97559 9.53132C3.91394 9.49591 3.86289 9.44465 3.82773 9.38287C3.79256 9.32108 3.77456 9.25101 3.77559 9.17993Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.535645 13.5H11.4642"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadAudioIcon;
