export const LanguageList = [
  // {
  //   label: "O'zbek",
  //   value: "de",
  // },
  {
    label: "O'zbek",
    value: "uz",
  },
  {
    label: "Русский",
    value: "ru",
  },
];
