import React, { FC } from "react";
import { CheckboxStyled } from "./Checkbox.style";
import { CheckboxPropsType } from "./Checkbox.types";
import { Checkbox as MUICheckbox } from "@mui/material";
import { Controller } from "react-hook-form";

const Checkbox: FC<CheckboxPropsType> = ({
  label = "",
  control,
  name,
  defaultChecked = false,
}) => {
  return (
    <CheckboxStyled
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultChecked || false}
          render={({ field }: any) => {
            return (
              <MUICheckbox
                defaultChecked={defaultChecked}
                checked={field.value || false}
                {...field}
              />
            );
          }}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
