import React from 'react'

const NewsIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7858 5.00001V16.4286C14.7858 17.1863 14.4848 17.9131 13.9489 18.4489C13.4131 18.9847 12.6864 19.2857 11.9286 19.2857H1.21436C1.97212 19.2857 2.69884 18.9847 3.23466 18.4489C3.77048 17.9131 4.0715 17.1863 4.0715 16.4286V3.57144C4.0715 2.81368 4.37252 2.08695 4.90834 1.55113C5.44415 1.01531 6.17088 0.714294 6.92864 0.714294H17.2858" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.2856 0.714294C17.9487 0.714294 18.5846 0.977686 19.0534 1.44653C19.5223 1.91537 19.7856 2.55125 19.7856 3.21429V4.28572C19.7856 4.47516 19.7104 4.65684 19.5764 4.7908C19.4425 4.92475 19.2608 5.00001 19.0714 5.00001H14.7856V3.21429C14.7856 2.55125 15.049 1.91537 15.5179 1.44653C15.9867 0.977686 16.6226 0.714294 17.2856 0.714294V0.714294Z" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.07129 5.71429H11.2141" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.64307 10H11.2145" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.64307 14.2857H11.2145" stroke="#000001" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default NewsIcon