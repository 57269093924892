import RichTextEditor from "@mantine/rte";
import styled from "styled-components";
import { ITextEditorStyled } from "./TextEditor.types";

export const TextEditorStyled = styled(RichTextEditor)<ITextEditorStyled | any>`
  z-index: 0;
  border: 1px solid ${(props) => (props.isError ? "red" : "#f1f1f1")};
  .quill {
    .ql-container {
      .ql-editor {
        min-height: 300px;
      }
    }
  }
  .ql-editing {
    left: 30px !important;
  }
`;
