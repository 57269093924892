import { Route, Routes as Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { LightTheme } from "styles/theme/lightTheme";
import Login from "pages/login/container";
import CommonProvider from "context/CommonProvider";
import { Toaster } from "react-hot-toast";
import Layout from "layout/container/Layout";
import { Suspense } from "react";
import Loader from "pages/loader/container/Loader";
import NotFound from "pages/notFound/container/NotFound";

import "styles/index.scss";

function App() {
  return (
    <Suspense fallback={<Loader fullWidth />}>
      <CommonProvider>
        <ThemeProvider theme={LightTheme}>
          <div className="app-container">
            <Suspense fallback={<Loader fullWidth />}>
              <Switch>
                <Route path="*" element={<Layout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/page-not-found" element={<NotFound />} />
              </Switch>
            </Suspense>
          </div>
        </ThemeProvider>
        <Toaster containerStyle={{ zIndex: "100000" }} />
      </CommonProvider>
    </Suspense>
  );
}

export default App;
