import styled from "styled-components";

export const NotFoundPageStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    margin-top: 40px;
    text-decoration: none;
  }
`;
