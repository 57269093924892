import React from "react";
import { CircularProgress as MUiCircularProgress } from "@mui/material";
import { ICircularProgress } from "./CircularProgress.types";

const CircularProgress = ({
  animationDuration = 500,
  disableShrink = true,
  size = 30,
  ...props
}: ICircularProgress) => {
  return (
    <MUiCircularProgress
      {...props}
      disableShrink={disableShrink}
      size={size}
      sx={{
        animationDuration: `${animationDuration}ms`,
      }}
    />
  );
};

export default CircularProgress;
