import React from "react";

const UploadAudioIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9285 8.35718H7.07136C6.36128 8.35718 5.78564 8.93281 5.78564 9.64289V16.0715C5.78564 16.7815 6.36128 17.3572 7.07136 17.3572H10.9285C11.6386 17.3572 12.2142 16.7815 12.2142 16.0715V9.64289C12.2142 8.93281 11.6386 8.35718 10.9285 8.35718Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.57129 9.64282V13.5"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4285 9.64282V13.5"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.57139 10.9285H1.28568C1.11518 10.9285 0.95167 10.8608 0.831111 10.7402C0.710552 10.6197 0.642822 10.4562 0.642822 10.2857V8.99997C0.642822 6.78351 1.5233 4.65784 3.09057 3.09057C4.65784 1.5233 6.78351 0.642822 8.99997 0.642822V0.642822C10.0974 0.642822 11.1842 0.858986 12.1981 1.27897C13.212 1.69896 14.1333 2.31454 14.9094 3.09057C15.6854 3.86661 16.301 4.78789 16.721 5.80182C17.1409 6.81576 17.3571 7.90249 17.3571 8.99997V10.2857C17.3571 10.4562 17.2894 10.6197 17.1688 10.7402C17.0483 10.8608 16.8847 10.9285 16.7143 10.9285H15.4285"
        stroke="#000001"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 11.5713V12.857"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadAudioIcon;
